#dashboard{
    width: 90vw;
    margin: 1rem auto;
}
#search{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}
box-icon{
    margin: 5px;
}
#get-started{
    margin-top: 23px;
    height: 203px;
width: 339px;
left: 36px;
top: 138px;
border-radius: 17px;
background:linear-gradient(180deg, rgba(235, 142, 33, 0.9) 0%, rgba(238, 188, 9, 0.612) 100%);
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}
#get-started h1{
    font-size: 20px;
}
#split{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;
}
#split p {
    flex: 8;
    font-size: 13px;
}
#split img{
    flex:5;
    height: 122px;
    width: 163px;
    left: 212px;
    top: 222px;
    border-radius: 0px;
}
#browse-scroll{
    background: linear-gradient(180deg, rgba(3, 88, 140, 0.13) 0%, rgba(26, 150, 225, 0.25) 100%);
    height: 115px;
width: 341px;
display: flex;
align-items: flex-start;
justify-content: flex-start;
overflow: scroll;
}

#department-card{
    min-height: 87px;
min-width: 90px;
border-radius: 22px;
background: rgba(255, 255, 255, 0.94);
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
margin: 14px 12px 14px 11px;
}
#department-card img{
    height: 28px;
width: 17px;
object-fit: cover;
}
#department-h1{
    font-size: 8px;
    text-align: center;
}
#browse-scroll-recent{
    min-height: 236px;
    height: max-content;
width: 341px;
border-radius: 0px;
background: linear-gradient(180deg, rgba(3, 88, 140, 0.13) 0%, rgba(26, 150, 225, 0.25) 100%);
display: flex;
align-items: flex-start;
justify-content: flex-start;
overflow: scroll;
}
#recent-card{
    margin: 24px 23px 50px 11px;
    max-height: 162px;
    min-height: 162px;
min-width: 137px;
border-radius: 22px;
background: #FFFFFFF0;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
padding: 0.2rem;
font-size: 7px;
}
#recent-card img{
    height: 71px;
width: 88px;
border-radius: 0px;

}
#categories-scroll{
    height: 343px;
    width: 341px;
    border-radius: 0px;
    background: linear-gradient(180deg, rgba(3, 88, 140, 0.13) 0%, rgba(26, 150, 225, 0.25) 100%);
}
#categories-scroll-grid{
    width: 100%;
    height: 100%;
    margin: auto;
display: grid;
grid-template-columns: repeat(3,1fr);
align-items: center;
justify-items: center;
padding: 1rem;
}
#category-card{
    height: 77px;
width: 83px;
border-radius: 19px;
background: #D9D9D9;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}
#category-card img{
    height: 35px;
width: 35px;
object-fit: contain;
}
#category-h1{
    margin-top: 5px;
    text-align: center;
    font-size: 8px;
}
#share-app{
    margin: 1rem 0;
height: 171px;
width: 339px;
border-radius: 17px;
background: linear-gradient(180deg, rgba(66, 166, 41, 0.9) 0%, rgba(121, 221, 43, 0.612) 100%);

}
#share-h1{
    margin: 1rem 0 0 1rem;
}
#split-share-app{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;
}
#split-share-app p {
    flex: 8;
    font-size: 13px;
}
#split-share-app img{
    flex:5;
    height: 100px;
width: 161px;
object-fit: contain;

}