section{
    width: 80vw;
    margin: auto;
}
#logo{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #03446C;
}
#h1{
    margin-bottom: 2rem;
    font-size: 1.5rem;
}
#h3{
    margin-bottom: 3rem;
}
#forgot-password{
    text-align: right;
    color: #1E1E1ECC;
}
#login-button{
    margin-top: 5rem;
    background: #174291;
    width: 191px;
height: 56px;
font-size: 18px;
margin-left:25%;
}